.merriweather-light {
    font-family: "Merriweather", system-ui;
    font-weight: 300;
    font-style: normal;
}

.merriweather-regular {
    font-family: "Merriweather", system-ui;
    font-weight: 400;
    font-style: normal;
}

.merriweather-bold {
    font-family: "Merriweather", system-ui;
    font-weight: 700;
    font-style: normal;
}

.merriweather-black {
    font-family: "Merriweather", system-ui;
    font-weight: 900;
    font-style: normal;
}

.merriweather-light-italic {
    font-family: "Merriweather", system-ui;
    font-weight: 300;
    font-style: italic;
}

.merriweather-regular-italic {
    font-family: "Merriweather", system-ui;
    font-weight: 400;
    font-style: italic;
}

.merriweather-bold-italic {
    font-family: "Merriweather", system-ui;
    font-weight: 700;
    font-style: italic;
}

.merriweather-black-italic {
    font-family: "Merriweather", system-ui;
    font-weight: 900;
    font-style: italic;
}

.montserrat {
    font-family: "Montserrat", system-ui;
    font-weight: normal;
    font-style: normal;
}

.montserrat-light {
    font-family: "Montserrat", system-ui;
    font-weight: 300;
    font-style: normal;
}

.montserrat-regular {
    font-family: "Montserrat", system-ui;
    font-weight: 400;
    font-style: normal;
}

.montserrat-bold {
    font-family: "Montserrat", system-ui;
    font-weight: 700;
    font-style: normal;
}

.montserrat-black {
    font-family: "Montserrat", system-ui;
    font-weight: 900;
    font-style: normal;
}

.montserrat-light-italic {
    font-family: "Montserrat", system-ui;
    font-weight: 300;
    font-style: italic;
}

.montserrat-regular-italic {
    font-family: "Montserrat", system-ui;
    font-weight: 400;
    font-style: italic;
}

.montserrat-bold-italic {
    font-family: "Montserrat", system-ui;
    font-weight: 700;
    font-style: italic;
}

.montserrat-black-italic {
    font-family: "Montserrat", system-ui;
    font-weight: 900;
    font-style: italic;
}
  

