:root {
    --black: #0B0D1A;
    --purple: #482188;
    --white: #fff;
    --gray: #ccc;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
 
        transform: translateY(0);
    }
}

.about .container {
    text-align: center;
    /* padding: 3rem 1rem; */
    padding: 0 !important;
    overflow: hidden; 
}

.about {
    /* background: linear-gradient(180deg, var(--black) 70%, var(--purple)); */
    background-color: var(--black);
    color: var(--white);
    text-align: center;
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
}

.about a {
    text-decoration: none;
    border-bottom: 1px solid var(--gray);
    color: var(--gray);
}

.about-container {
    z-index: 1;
    width: 100%;
    position: relative;
    animation: fadeInUp 1s ease forwards;
}

.about-container .container {
    padding: 10px;
}

.about-content {
    position: relative;
    z-index: 5;
    animation: fadeInUp 1s ease forwards;
    margin-bottom: 2rem;
    width: 100%;
    min-height: 90vh;
}

@keyframes grainAnim {
    0%, 100% { transform: translate(0, 0); }
    10% { transform: translate(-5%, -10%); }
    30% { transform: translate(3%, -15%); }
    50% { transform: translate(12%, 9%); }
    70% { transform: translate(9%, 4%); }
    90% { transform: translate(-1%, 7%); }
}

.grain-effect-2 {
    position: absolute;
    top: -25%;
    left: -25%;
    width: 150%;
    height: 150%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.1) 1%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.1;
    filter: url(#grainFilter2);
    pointer-events: none;
    animation: grainAnim 8s steps(10) infinite;
}