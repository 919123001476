:root {
    --black: #0B0D1A;
    --purple: #482188;
    --white: #fff;
    --gray: #ccc;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* SECTION 1 */

.section1 {
    background: linear-gradient(180deg, var(--black) 70%, var(--purple));
}

.parallax-hero {
    /* background: linear-gradient(135deg, #1d1d1d 0%, #2b2b2b 100%); */
    /* background: pink; */
    min-height: 100vh;
    position: relative;
    text-align: center;
    padding: 3rem 1rem;
    overflow: hidden; 
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.4); */
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
    animation: fadeInUp 1s ease forwards;
    margin-bottom: 2rem;
}

.title {
    margin-top: 13% !important;
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.description {
    max-width: 700px;
    margin: 0 auto;
    color: #ccc;
}

.parallax-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateY(0);
    will-change: transform;
    transition: transform 0.2s ease-out;
    filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.5));
}

.image-container {
    width: 100%;
    height: 90vh;
    transform: translateY(0%)
    /* overflow: hidden; */
    /* transform: translateX(-50%); */
}

@keyframes grainAnim {
    0%, 100% { transform: translate(0, 0); }
    10% { transform: translate(-5%, -10%); }
    30% { transform: translate(3%, -15%); }
    50% { transform: translate(12%, 9%); }
    70% { transform: translate(9%, 4%); }
    90% { transform: translate(-1%, 7%); }
}

.grain-container {
    /* filter: brightness(100%) contrast(130%); */
}

.grain-effect {
    position: absolute;
    top: -25%;
    left: -25%;
    width: 150%;
    height: 150%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.1) 1%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.1;
    filter: url(#grainFilter);
    pointer-events: none;
    animation: grainAnim 8s steps(10) infinite;
}

.background-slash {
    position: absolute;
    top: -45%;
    left: -25%;
    width: 170%;
    height: 170%;
    z-index: 1;
}

.background-slash-path {
    stroke-width: 0;
    fill: url(#grad1);
    z-index: 3;
    filter: blur(15px);
}

/* SECTION 2 */

.section2 {
    /* background-color: #101010; */
    min-height: 110vh;
    padding: 3rem 1rem;
    margin-top: 10%;
    color: white;
    position: relative;
    overflow: hidden;
    z-index: 2;
    background-color: var(--black);
    /* backgroundColor: '#f0f4f8'; */
}

.section2 .container {
    margin-top: 10%;
}

.feature-container {
    padding-right: 50px;
}

.feature {
    cursor: pointer;
    transition:cubic-bezier(0.895, 0.03, 0.685, 0.22);
    margin-bottom: 5%;
    border: 1px solid var(--gray);
    border-radius: 10px;
    padding: 20px 10px 10px 10px;
    filter: url(#grain);
    transition: all ease-in-out 0.3s;
}

.feature:hover {
    transform: scale(1.0);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
    filter: url(#grain);
    backdrop-filter: blur(10px);
}

.hills-svg {
    width: 100%;
    height: 100%;
}

.blob {
    position: absolute;
    top: 30%; 
    left: 70%;
    height: 300px;
    width: 300px;
    background-color: var(--purple); 
    border-radius: 50%;
    filter: blur(120px);
    z-index: -1;
}

/* SECTION 3 */

.section3 {
    min-height: 70vh;
    padding: 1rem 1rem;
    background: var(--black);
    filter: url(#grain);
    color: var(--gray);    
}

.coming-soon-text {

}