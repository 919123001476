:root {
    --black: #0B0D1A;
    --purple: #482188;
    --white: #fff;
    --gray: #ccc;
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

/* FOOTER */

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.footer-container svg {
    width: 0px;
    height: 0px;
}   

footer {
    background: var(--black);
    margin-top: 0vh;
    z-index: 500;
    width: 100%;
    height: 10vh;
    color: var(--gray);

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: 2rem 2vw;
    position: relative;
    filter: url(#grain);
}

footer::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
}

footer p {
    width: 100%;
}

/* NAVBAR */
.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    color: var(--gray);
}

.navbar-brand {
    text-decoration: none;
    color: var(--gray);
    font-size: 1.5rem;
    font-weight: 700;
}

.navbar-link {
    margin-right: 30px;
    text-decoration: none;
    color: var(--gray);
}